<!-- 工时管理 > 工时填报 > 工时填报(弹出框) -->
<template>
  <div>
    <el-dialog
      width="80%"
      :before-close="handleClose"
      :visible.sync="showDayEditDialog"
      :close-on-click-modal="false"
      class="fix-dialog-position"
    >
      <div v-loading="loading" style="position: relative">
        <div class="date_div">
          <h3>工时填报-当前日期：{{ getDate }}</h3>
        </div>
        <div class="form_box">
          <el-tabs v-model="parameter.manhourType">
            <el-tab-pane name="0">
              <span slot="label">项目工时</span>
            </el-tab-pane>
            <el-tab-pane name="2">
              <span slot="label">商务工时</span>
            </el-tab-pane>
            <el-tab-pane name="1">
              <span slot="label">管理工时</span>
            </el-tab-pane>
          </el-tabs>
          <el-scrollbar class="scrollbar_div" ref="leftScrollbar">
            <div v-show="parameter.manhourType == 0">
              <div class="project_div">
                <div style="display: flex; align-items: center; height: 43px">
                  项目名称：
                  <el-autocomplete
                    style="width: 500px"
                    v-model="searchProjectName"
                    :fetch-suggestions="projectQuerySearch"
                    placeholder="请输入或选择项目名"
                    @select="onProkectNameSelect"
                    :popper-append-to-body="false"
                  >
                  </el-autocomplete>
                </div>
                <ul class="form_header">
                  <li>项目名称</li>
                  <li>任务名称</li>
                  <li>有无任务</li>
                  <li>有无重要活动</li>
                  <li>是否外勤</li>
                  <li>工时(小时)</li>
                  <li>工作内容</li>
                </ul>
                <ul
                  class="form_body"
                  v-for="(item, index) in projectList"
                  :key="index"
                  v-if="item.checkStatus != 4"
                >
                  <li>
                    <el-select
                      v-model="item.projectName"
                      @visible-change="onChangeProject"
                      @input="selectChangeParent($event, index)"
                      placeholder="请选择补录项目名称"
                    >
                      <el-option
                        v-for="item in listProject"
                        v-show="!selectProIdArr.includes(item.projectId)"
                        :key="item.projectId"
                        :label="item.projectName"
                        :value="item.projectId"
                      >
                      </el-option>
                    </el-select>
                  </li>
                  <li>
                    <el-select
                      v-model="item.projectTaskId"
                      :clearable="false"
                      placeholder="请选择项目任务"
                      ><template v-for="task in item.xmTaskAssignList">
                        <el-option :key="task.id" :label="task.taskName" :value="task.id">
                        </el-option>
                      </template>
                    </el-select>
                  </li>
                  <!-- 有无任务复选框区域 -->
                  <li>
                    <div class="out-box">
                      <div>
                        <el-checkbox v-model="item.isTask" disabled>有</el-checkbox>
                      </div>
                    </div>
                  </li>
                  <!-- 有无重要活动复选框区域 -->
                  <li>
                    <div class="out-box">
                      <div>
                        <el-checkbox
                          v-model="item.isGoOut"
                          @change="onOutSwitchChange(item, index, $event, 'project')"
                          >有</el-checkbox
                        >
                      </div>
                      <el-link
                        v-if="item.isGoOut"
                        class="out-a"
                        type="warning"
                        @click="openOutReportDialog(item, index, 'project')"
                        >查看报告</el-link
                      >
                    </div>
                  </li>
                  <!--是否外勤复选框区域 -->
                  <li>
                    <div class="out-box">
                      <div>
                        <el-checkbox
                          v-model="item.isOutWork"
                          :disabled="true"
                          @change="onOutWorkSwitchChange(item, index, $event, 'project')"
                          >是</el-checkbox
                        >
                      </div>
                      <el-link
                        v-if="item.isOutWork"
                        class="out-a"
                        type="warning"
                        @click="openOutWorkDialog(item, index, 'project')"
                        >查看报告</el-link
                      >
                    </div>
                  </li>
                  <li>
                    <el-input
                      v-model.number="item.hour"
                      @input="v => (item.hour = v.replace(/[^\d.]/g, ''))"
                    >
                    </el-input>
                    <!-- onkeyup="value=value.replace(/[^\d]/g,'')"  -->
                  </li>
                  <li>
                    <el-input
                      type="textarea"
                      v-model="item.workContent"
                      :autosize="{ minRows: 4, maxRows: 5 }"
                    >
                    </el-input>
                  </li>
                  <li>
                    <!-- <li style="transform: translateY(10px)">
                    <el-button
                      type="danger"
                      size="mini"
                      icon="el-icon-delete"
                      circle
                      @click="projectDeleteItem(index)"
                    >
                    </el-button> -->
                    <i
                      v-if="item.isTaskProject == 2"
                      class="el-icon-circle-plus-outline btn-add"
                      @click="onAddProject(item, index)"
                    ></i>
                    <i
                      class="el-icon-remove-outline btn-delete"
                      @click="projectDeleteItem(index)"
                    ></i>
                  </li>
                </ul>
              </div>
              <div class="add_btn">
                <p @click="handleAddProject">
                  <i class="el-icon-circle-plus-outline add_ico"></i>
                  <span>新增加项目工时</span>
                </p>
              </div>
            </div>
            <div v-show="parameter.manhourType == 2">
              <div class="business_div">
                <div style="display: flex; align-items: center; height: 43px">
                  商务名称：
                  <el-autocomplete
                    style="width: 500px"
                    v-model="searchBusinessName"
                    :fetch-suggestions="businessQuerySearch"
                    placeholder="请输入或选择商务名"
                    @select="onBusinessNameSelect"
                    :popper-append-to-body="false"
                  >
                  </el-autocomplete>
                </div>
                <ul class="form_header">
                  <li>商务名称</li>
                  <li>商务时间</li>
                  <li>有无重要活动</li>
                  <li>是否外勤</li>
                  <li>工时(小时)</li>
                  <li>工作内容</li>
                </ul>
                <ul
                  class="form_body"
                  v-for="(item, index) in businessList"
                  :key="index"
                  v-if="item.checkStatus != 4"
                >
                  <li>
                    <el-select
                      v-model="item.businessName"
                      @visible-change="onChangeBusiness"
                      @input="selectChangeBusiness($event, index)"
                      placeholder="请选择补录商务名称"
                    >
                      <el-option
                        v-for="item in listBusiness"
                        v-show="!selectBusinessIdArr.includes(item.id)"
                        :key="item.id"
                        :label="item.businessName"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                  </li>
                  <li>
                    <div>
                      <p>{{ item.begin_time ? item.begin_time : '暂无' }}</p>
                      <p>/</p>
                      <p>{{ item.end_time ? item.end_time : '暂无' }}</p>
                    </div>
                  </li>
                  <!-- 有无重要活动复选框区域 -->
                  <li>
                    <div class="out-box">
                      <div>
                        <el-checkbox
                          v-model="item.isGoOut"
                          @change="onOutSwitchChange(item, index, $event, 'business')"
                          >有</el-checkbox
                        >
                      </div>
                      <el-link
                        v-if="item.isGoOut"
                        class="out-a"
                        type="warning"
                        @click="openOutReportDialog(item, index, 'business')"
                        >查看报告</el-link
                      >
                    </div>
                  </li>
                  <!--是否外勤复选框区域 -->
                  <li>
                    <div class="out-box">
                      <div>
                        <el-checkbox
                          v-model="item.isOutWork"
                          :disabled="true"
                          @change="onOutWorkSwitchChange(item, index, $event, 'business')"
                          >是</el-checkbox
                        >
                      </div>
                      <el-link
                        v-if="item.isOutWork"
                        class="out-a"
                        type="warning"
                        @click="openOutWorkDialog(item, index, 'business')"
                        >查看报告</el-link
                      >
                    </div>
                  </li>
                  <li>
                    <el-input
                      v-model.number="item.hour"
                      @input="v => (item.hour = v.replace(/[^\d.]/g, ''))"
                    >
                    </el-input>
                    <!-- onkeyup="value=value.replace(/[^\d]/g,'')"  -->
                  </li>
                  <li>
                    <el-input
                      type="textarea"
                      v-model="item.workContent"
                      :autosize="{ minRows: 4, maxRows: 5 }"
                    >
                    </el-input>
                  </li>
                  <li style="transform: translateY(10px)">
                    <el-button
                      type="danger"
                      size="mini"
                      icon="el-icon-delete"
                      circle
                      @click="businessDeleteItem(index)"
                    >
                    </el-button>
                  </li>
                </ul>
              </div>
              <div class="add_btn">
                <p @click="handleAddBusiness">
                  <i class="el-icon-circle-plus-outline add_ico"></i>
                  <span>新增加商务工时</span>
                </p>
              </div>
            </div>

            <div v-show="parameter.manhourType == 1">
              <div class="work_div">
                <ul class="form_header">
                  <li>组织名称</li>
                  <li>有无重要活动</li>
                  <li>是否外勤</li>
                  <li>工时(小时)</li>
                  <li>工作内容</li>
                </ul>
                <ul
                  class="form_body"
                  v-for="(item, index) in workList"
                  :key="index"
                  v-if="item.checkStatus != 4"
                >
                  <li>
                    <!-- <el-select
                    v-model="item.companyType"
                    @visible-change="onChangeWork"
                    placeholder="请选择组织名称"
                  >
                    <el-option
                      v-for="(item, i) in listDept"
                      v-show="!selectIdsArr.includes(item)"
                      :key="i"
                      :label="i"
                      :value="item"
                    >
                    </el-option>
                  </el-select> -->
                    <DeptCascader
                      :placeholder="'请选择组织名称'"
                      :deptList="deptList"
                      :deptIdArr="item.deptIdArr"
                      style="width: 100%"
                      :clearable="false"
                      :showAllLevels="true"
                      @changeValue="item.deptId = $event"
                      @changeValueArr="item.deptIdArr = $event"
                    />
                  </li>
                  <!-- 有无重要活动复选框区域 -->
                  <li>
                    <div class="out-box">
                      <div>
                        <el-checkbox
                          v-model="item.isGoOut"
                          @change="onOutSwitchChange(item, index, $event, 'organization')"
                          >有</el-checkbox
                        >
                      </div>
                      <el-link
                        v-if="item.isGoOut"
                        class="out-a"
                        type="warning"
                        @click="openOutReportDialog(item, index, 'organization')"
                        >查看报告</el-link
                      >
                    </div>
                  </li>
                  <!-- 是否外勤 -->
                  <li>
                    <div class="out-box">
                      <div>
                        <el-checkbox
                          v-model="item.isOutWork"
                          :disabled="true"
                          @change="onOutWorkSwitchChange(item, index, $event, 'organization')"
                          >是</el-checkbox
                        >
                      </div>
                      <el-link
                        v-if="item.isOutWork"
                        class="out-a"
                        type="warning"
                        @click="openOutWorkDialog(item, index, 'organization')"
                        >查看报告</el-link
                      >
                    </div>
                  </li>
                  <li>
                    <el-input
                      v-model="item.hour"
                      @input="v => (item.hour = v.replace(/[^\d.]/g, ''))"
                    >
                    </el-input>
                  </li>
                  <li>
                    <el-input
                      v-model="item.workContent"
                      type="textarea"
                      :autosize="{ minRows: 4, maxRows: 5 }"
                    >
                    </el-input>
                  </li>
                  <li style="transform: translateY(10px)">
                    <el-button
                      type="danger"
                      size="mini"
                      icon="el-icon-delete"
                      circle
                      @click="handleDeleteItem(index)"
                    >
                    </el-button>
                  </li>
                </ul>
              </div>
              <div class="add_btn">
                <p @click="handleAddItem">
                  <i class="el-icon-circle-plus-outline add_ico"></i>
                  <span>新增加管理工时</span>
                </p>
              </div>
            </div>
          </el-scrollbar>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          class="teal_bg"
          type="success"
          size="small"
          :loading="loadSubmit"
          @click="handleSubmitRule"
        >
          提交
        </el-button>
        <el-button type="info" class="lightGrey_bg" size="small" @click="handleClose"
          >关闭</el-button
        >
      </span>
    </el-dialog>

    <EditDialog
      width="50%"
      :isShow.sync="isOutReportDialogShow"
      :isReturn="true"
      @closeFn="onOuReportDialogClose"
    >
      <div slot="title" class="title">重要活动报告</div>
      <div slot="content" class="content" style="min-height: 480px; padding-left: 20px">
        <el-form
          style="width: 100%"
          :model="outReportData"
          :rules="outReportRules"
          ref="outReportForm"
          label-width="107px"
          label-position="left"
        >
          <el-form-item label="报告类型" prop="type">
            <el-radio-group v-model="outReportData.type">
              <el-radio label="WRITTEN">书面报告</el-radio>
              <el-radio label="ORAL">口头报告</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="活动开始时间"
            style="width: 50%; display: inline-block"
            prop="startDate"
          >
            <el-date-picker
              v-model="outReportData.startDate"
              align="right"
              type="date"
              placeholder="选择日期"
              :picker-options="timePickerOptions"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item
            label="活动结束时间"
            style="width: 50%; display: inline-block"
            prop="endDate"
          >
            <el-date-picker
              v-model="outReportData.endDate"
              align="right"
              type="date"
              placeholder="选择日期"
              :picker-options="endDateOptions"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="活动地点" prop="place">
            <el-input v-model.trim="outReportData.place"></el-input>
          </el-form-item>
          <el-form-item :error="outReportData.fileErrMsg">
            <template slot="label">
              <template> 附件</template>
            </template>
            <div class="upload-btn-box">
              <div class="icon-out">
                <Upload type="WCHDBG" :fields="fields" @upload="upload"></Upload>
              </div>

              <div v-if="canGoOutSubmit">
                <el-button
                  type="success"
                  size="small"
                  style="margin-left: 50px"
                  v-if="outReportData.type == 'WRITTEN'"
                  @click="downloadFile"
                  >下载重要活动报告模板</el-button
                >
                <el-alert
                  style="margin-left: 50px"
                  v-else
                  title="如有微信截图，请在附件上传中上传"
                  type="info"
                >
                </el-alert>
              </div>
            </div>

            <div
              v-for="(n, index) in outReportData.manhourFileList"
              :key="index"
              style="margin-top: 5px"
              class="file-list"
            >
              <a
                class="fj-text file-name"
                target="_blank"
                style="text-decoration: revert; color: #409eff; padding-right: 8px"
                :href="`${filepath}${n.filePath}`"
              >
                {{ n.fileName }}
              </a>

              <i @click="del(index)" class="iconfont iconerror icon-out"></i>
            </div>
          </el-form-item>
          <el-form-item label="活动说明" prop="content">
            <template slot="label">
              活动说明
              <el-tooltip class="item" effect="light" placement="top">
                <div slot="content" style="font-size: 18px; color: #000">
                  编写提示：<br />
                  一、汇报事由：XX时间参与了XX组织的XX活动，活动主题是XXX，同时参加活动的还有XX单位/企业，
                  <br />　　我(或我司XX)以XX角色（汇报者或参会人员等）在活动中作出了汇报（或其他事项）。<br />
                  二、梳理活动主要情况，汇报需要特别关注的重点事项。<br />
                  三、结合实际，针对重点问题提出切实可行的建议。
                </div>
                <i class="el-icon-question"></i>
              </el-tooltip>
            </template>
            <el-input
              type="textarea"
              v-model.trim="outReportData.content"
              :autosize="{ minRows: 8, maxRows: 9999 }"
              placeholder="编写提示：
一、汇报事由：XX时间参与了XX组织的XX活动，活动主题是XXX，同时参加活动的还有XX单位/企业，我(或我司XX)以XX角色（汇报者或参会人员等）在活动中作出了汇报（或其他事项）。
二、梳理活动主要情况，汇报需要特别关注的重点事项。
三、结合实际，针对重点问题提出切实可行的建议。"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="footer">
        <el-button type="primary" plain @click="saveOutReport">保存</el-button>
        <el-button type="danger" plain @click="deleteOutReport" v-if="isDetailInDialog"
          >删除</el-button
        >
        <el-button type="info" plain @click="onOuReportDialogClose">关闭</el-button>
      </div>
    </EditDialog>

    <EditDialog
      width="45%"
      :isShow.sync="isOutWorkDialogShow"
      :isReturn="true"
      @closeFn="onOutWorkDialogClose"
    >
      <div slot="title" class="title">外勤报告</div>
      <div slot="content" class="content" style="min-height: 480px; padding-left: 20px">
        <el-form style="width: 100%" :model="outWorkInfo" label-width="107px" label-position="left">
          <el-form-item>
            <template slot="label"> <span style="color: red">*</span> 证明人 </template>

            <el-select v-model="outWorkInfo.witness" filterable placeholder="请选择证明人">
              <el-option
                v-for="item in userList"
                :key="item.id"
                :label="item.userName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <template slot="label">
              <span style="color: red">*</span> 附件
              <el-tooltip class="item" effect="light" placement="top">
                <div slot="content" style="font-size: 18px; color: #000">
                  工作派遣、聊天记录、通知函。
                </div>
                <i class="el-icon-question"></i>
              </el-tooltip>
            </template>
            <div class="upload-btn-box">
              <div class="icon-out">
                <Upload type="WQ" :fields="fields" @upload="outWorkUpload"></Upload>
              </div>
            </div>

            <div
              v-for="(n, index) in outWorkInfo.manhourFileList"
              :key="index"
              style="margin-top: 5px"
              class="file-list"
            >
              <a
                class="fj-text file-name"
                target="_blank"
                style="text-decoration: revert; color: #409eff; padding-right: 8px"
                :href="`${filepath}${n.filePath}`"
              >
                {{ n.fileName }}
              </a>

              <i @click="outWorkDel(index)" class="iconfont iconerror icon-out"></i>
            </div>
          </el-form-item>
          <el-form-item label="外勤说明" prop="content" style="width: 100%">
            <el-input
              type="textarea"
              v-model.trim="outWorkInfo.content"
              :autosize="{ minRows: 8, maxRows: 9999 }"
              placeholder="请输入外勤说明"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="footer">
        <el-button type="primary" plain @click="saveOutWork">保存</el-button>
        <el-button type="danger" plain @click="deleteOutWork" v-if="isOutWorkDialog"
          >删除</el-button
        >
        <el-button type="info" plain @click="onOutWorkDialogClose">关闭</el-button>
      </div>
    </EditDialog>
  </div>
</template>

<script>
import { dateFormater } from '@/util/common'
import { mapState } from 'vuex'

export default {
  components: {
    EditDialog: () => import('@/components/EditDialog.vue'),
    Upload: () => import('@/components/upload/Upload.vue'),
    DeptCascader: () => import('@/components/dept/DeptCascader.vue'),
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
  },
  props: {
    showDayEditDialog: {
      type: Boolean,
      default: false,
    },
    getDate: {
      type: String,
      default: '',
    },
    dayForm: {
      type: Object,
      default: () => {
        return {}
      },
    },
    ZHB_projectManager: {
      type: Array,
      default: [],
    },
    ZHB_member: {
      type: Array,
      default: [],
    },
    activeName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      searchProjectName: '',
      searchBusinessName: '',
      userId: JSON.parse(localStorage.getItem('userInfo')).id, // 登录用户 id
      loading: false, // 加载
      projectList: [], // 参与项目 列表数据
      workList: [], // 工作 列表数据
      formData: [], // 保存数据
      loadSave: false, // 保存 按钮 -- 加载
      loadSubmit: false, // 提交 按钮 -- 加载
      isSubmit: false, // 判断 是否 已提交
      isSave: true, // 判断 是否 已保存
      checkStatus: 0, //判断 是否 该项目是否提交过
      listDept: [], // 所有组织列表
      listProject: [], //参与得项目列表
      selectIdsArr: [], //处理组织重复填写
      selectProIdArr: [], //处理项目重复填写
      deleteProjectList: [],

      isOutReportDialogShow: false,
      nowClickProjectHourIndex: '', //当前点击的项目级工时下标，用来赋值
      outReportRules: {
        type: [{ required: true, message: '请选择报告类型', trigger: 'change' }],
        startDate: [{ type: 'date', required: true, message: '请选择日期', trigger: 'change' }],
        endDate: [{ type: 'date', required: true, message: '请选择日期', trigger: 'change' }],
        place: [{ required: true, message: '请输入活动地点', trigger: 'blur' }],
        content: [{ required: true, message: '请填写活动说明', trigger: 'blur' }],
      },
      isDetailInDialog: false, //用于标识当前外出活动弹框是新增弹框还是详情弹框
      outReportType: '', //用于判断当前外出活动报告对话框是项目组还是组织级
      canGoOutSubmit: true,
      //外出报告弹框数据源
      outReportData: {
        type: 'WRITTEN', //类型
        startDate: '', //实际开始时间
        endDate: '', //实际结束时间
        place: '',
        content: '',
        fileList: [],
        fileErrMsg: '', //附件上传校验文字
      },

      filepath: process.env.VUE_APP_FILEPATH,
      fields: {
        name: 'fileName',
        path: 'filePath',
        type: 'type',
      },
      // 日期选择器配置快捷方式
      timePickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date())
            },
          },
          {
            text: '昨天',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', date)
            },
          },
          {
            text: '一周前',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            },
          },
        ],
      },
      endDateOptions: {
        disabledDate: time => {
          const startDate = new Date(this.outReportData.startDate)
          if (startDate) {
            return time.getTime() < startDate.getTime()
          } else {
            return false
          }
        },
      },
      deptList: [],
      deptNameList: [],
      isOutWorkDialogShow: false, // 外勤弹窗
      isOutWorkDialog: false, //用于标识当前外勤弹框是新增弹框还是详情弹框
      canOutWorkSubmit: true,
      // 外勤弹框数据源
      outWorkInfo: {
        manhourId: '',
        witness: '',
        content: '',
        manhourFileList: [],
        deleteManhourFileIdList: [],
      },
      userList: [],
      userList: [],
      parameter: {
        manhourType: 0,
      },
      businessList: [],
      listBusiness: [],
      selectBusinessIdArr: [],
      deleteBusinessList: [],
    }
  },
  created() {
    this.$api.sysDept
      .listDept()
      .then(res => {
        this.deptNameList = res.data
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.user
      .listStaff({
        pageNow: 1,
        pageSize: 9999,
      })
      .then(res => {
        this.userList = res.data?.records.deepClone()
        this.userList = this.userList.filter(v => v.id != this.userInfo.staffId)
      })
      .catch(err => {
        console.log(err)
      })
  },
  mounted() {
    this.getlistDept() /** 加载 所有部门数据  **/
    this.getHourDetailedData() /** 加载 "项目 填报工时" 详情数据 **/
    this.getlistProject() /** 加载 用户参与项目数据  **/
  },
  methods: {
    /** 加载 "项目 填报工时" 详情数据 **/
    getHourDetailedData() {
      this.loading = true
      this.manhourDate = this.getDate
      this.$api.hour
        .getManhourByDate(this.getDate)
        .then(res => {
          if (res.data.length > 0) {
            this.loading = false
            let _work = []
            let _project = []
            let businessList = []
            for (let i = 0; i < res.data.length; i++) {
              if (res.data[i].manhourType == 1) {
                //组织级
                _work.push({
                  ...res.data[i],
                  type: '组织级',
                  manhourId: res.data[i].id,
                  isGoOut: res.data[i].outgoingType == 'HAVE' ? true : false,
                  isOutWork: res.data[i].isTraveling == 'Y' ? true : false,
                  outReportType: 'organization',
                })
              }
              if (res.data[i].manhourType == 0) {
                // 项目级
                _project.push({
                  ...res.data[i],
                  type: '项目级',
                  projectName: res.data[i].workName,
                  manhourId: res.data[i].id,
                  isGoOut: res.data[i].outgoingType == 'HAVE' ? true : false,
                  isOutWork: res.data[i].isTraveling == 'Y' ? true : false,
                  outReportType: 'project',
                  isTask: res.data[i].isTaskProject == 2 ? true : false,
                })
              }
              if (res.data[i].manhourType == 2) {
                // 商务级
                businessList.push({
                  ...res.data[i],
                  type: '商务级',
                  businessName: res.data[i].workName,
                  manhourId: res.data[i].id,
                  isGoOut: res.data[i].outgoingType == 'HAVE' ? true : false,
                  isOutWork: res.data[i].isTraveling == 'Y' ? true : false,
                  outReportType: 'business',
                })
              }
            }
            this.projectList = _project
            this.workList = _work
            this.businessList = businessList
            this.onChangeProject()
            this.onChangeBusiness()
          }
        })
        .catch(err => {
          console.log(err)
        })
    },

    /**
     * 点击 "组织级类型工时 -- 列表 - 删除" 图标
     * @param index     下标值
     *
     */
    handleDeleteItem(index) {
      if (this.workList[index].checkStatus || this.workList[index].checkStatus == 0) {
        this.workList[index].checkStatus = 4
      } else {
        this.workList.splice(index, 1)
      }
    },
    onAddProject(row, index) {
      const item = { ...row }
      if (item.id) {
        delete item.id
      }
      item.workContent = '9：00-18：30 \n1、\n2、\n3、'
      item.projectTaskId = ''
      item.hour = ''
      item.isOutWork = false
      item.isGoOut = false
      item.checkStatus = 0
      this.projectList.splice(index + 1, 0, item)
    },
    /**
     * 点击 "项目级类型工时 -- 列表 - 删除" 图标
     * @param index     下标值
     *
     */
    projectDeleteItem(index) {
      const item = this.projectList.splice(index, 1)
      if (item[0].checkStatus || item[0].checkStatus == 0) {
        item[0].checkStatus = 4
        this.deleteProjectList.push(item[0])
      }
      this.onChangeProject()
    },
    /**
     * 点击 "商务级类型工时 -- 列表 - 删除" 图标
     * @param index     下标值
     *
     */
    businessDeleteItem(index) {
      const item = this.businessList.splice(index, 1)
      if (item[0].checkStatus || item[0].checkStatus == 0) {
        item[0].checkStatus = 4
        this.deleteBusinessList.push(item[0])
      }
      this.onChangeBusiness()
    },
    /** 点击 "新增加商务级工时" 按钮 **/
    handleAddBusiness() {
      this.businessList.push({
        id: '', // id
        workName: '', // 商务名称
        hour: '', // 工时
        type: '商务级', // 类型
        manhourType: 2, // 类型key
        workContent: '9：00-18：30 \n1、\n2、\n3、', //工作内容
        manhourDate: new Date(this.getDate).getTime(), // 填报时间
        userId: JSON.parse(localStorage.getItem('userInfo')).id, // 登录用户 id
      })
    },
    /** 点击 "新增加项目级工时" 按钮 **/
    handleAddProject() {
      this.projectList.push({
        id: '', // id
        workName: '', // 项目名称
        hour: '', // 工时
        type: '项目级', // 类型
        manhourType: 0, // 类型key
        workContent: '9：00-18：30 \n1、\n2、\n3、', //工作内容
        manhourDate: new Date(this.getDate).getTime(), // 填报时间
        userId: JSON.parse(localStorage.getItem('userInfo')).id, // 登录用户 id
        isTask: false,
        projectTaskId: '',
        isTaskProject: '',
        xmTaskAssignList: [],
      })
    },
    /** 点击 "新增加组织级工时" 按钮 **/
    handleAddItem() {
      const deptArr = this.findDeepestNode(this.deptList)
      this.workList.push({
        id: '', // id
        workName: '', // 工作名称
        hour: '', // 工时
        type: '组织级', // 类型
        manhourType: 1, // 类型key
        workContent: '9：00-18：30 \n1、\n2、\n3、', // 工作内容
        manhourDate: new Date(this.getDate).getTime(), // 填报时间
        userId: JSON.parse(localStorage.getItem('userInfo')).id, // 登录用户 id
        outgoingType: 'NO',
        isGoOut: false,
        isTraveling: 'N',
        isOutWork: false,
        deptIdArr: deptArr,
        deptId: deptArr[deptArr.length - 1],
      })
      this.handleScrollBottom()
    },
    // 获取用户所属小组或部门
    findDeepestNode(tree) {
      let deepestInfo = { level: -1, path: [] }
      // 递归遍历函数
      function traverse(node, currentLevel, currentPath) {
        // 更新当前路径
        const newPath = [...currentPath, node.id]
        // 如果当前层级更深，则更新最深节点信息
        if (currentLevel > deepestInfo.level) {
          deepestInfo.level = currentLevel
          deepestInfo.path = newPath
        }
        // 递归遍历子节点
        if (node.children && node.children.length > 0) {
          for (const child of node.children) {
            traverse(child, currentLevel + 1, newPath)
          }
        }
      }
      // 从根节点开始遍历
      for (const node of tree) {
        traverse(node, 0, [])
      }
      return deepestInfo.path
    },
    /** 点击 "提交" 按钮得验证 **/

    handleSubmitRule() {
      //填报规则检验
      let time = 0
      let tag = 0
      let projectTaskId = ''
      for (let l = 0; l < this.projectList.length; l++) {
        const e = this.projectList[l]
        if (e.hour && e.checkStatus != 4) {
          time += Number(e.hour)
        }
        if (e.hour > 0) {
          if (!e.workContent) {
            this.$message('填了项目工时的工作内容不能为空!')
            return
          }
        }
        if (e.checkStatus != 4) {
          if (e.isTaskProject == 2 && !e.projectTaskId && e.hour > 0) {
            this.$message({
              type: 'error',
              message: '请选择项目任务',
            })
            return
          }
          if (e.isTaskProject == 2 && e.projectTaskId && !e.hour) {
            this.$message({
              type: 'error',
              message: '请填写项目任务工时',
            })
            return
          }

          if (projectTaskId !== e.projectTaskId) {
            projectTaskId = e.projectTaskId
            tag = 0
            if (e.isTaskProject == 2 && e.projectTaskId && e.hour > 0) {
              tag++
            }
          } else {
            if (e.isTaskProject == 2 && e.projectTaskId && e.hour > 0) {
              if (++tag > 1) {
                this.$message({
                  type: 'error',
                  message: '项目任务不能重复',
                })
                return
              }
            }
          }
        }
      }
      for (let l = 0; l < this.businessList.length; l++) {
        const e = this.businessList[l]
        if (e.hour && e.checkStatus != 4) {
          time += Number(e.hour)
        }
        if (e.hour > 0) {
          if (!e.workContent) {
            this.$message('填了商务工时的工作内容不能为空!')
            return
          }
        }
      }

      let departmentIdArr = []
      for (let l = 0; l < this.workList.length; l++) {
        const e = this.workList[l]
        if (e.hour && e.checkStatus != 4) {
          time += Number(e.hour)
        }
        if (e.hour > 0) {
          if (!e.workContent) {
            this.$message('填了管理工时的工作内容不能为空!')
            return
          }
        }
        if (!e.deptId) {
          this.$message({
            type: 'error',
            message: '组织级工作名称不能为空',
          })
          return
        }
        departmentIdArr.push(e.deptId)
      }
      function hasDuplicate(arr) {
        return new Set(arr).size !== arr.length
      }

      if (hasDuplicate(departmentIdArr)) {
        this.$message({
          type: 'error',
          message: '相同部门组织级工时不能重复',
        })
        return
      }

      if (time > 24) {
        this.$message('当天工作时长不能大于24个小时!')
        return
      }

      let tips = ''

      if (time > 12 || time == 0) {
        if (time > 12) {
          tips = '当日填报工时已超过12小时，确认提交？'
        } else {
          tips = '当日仅填报工时0小时，确认提交？'
        }

        this.$confirm(tips, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.handleSubmit()
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消',
            })
          })
      } else {
        this.handleSubmit()
      }
    },
    /**  "提交"  **/
    handleSubmit() {
      let _project = []
      let _work = []
      let _business = []

      for (let i = 0; i < this.projectList.length; i++) {
        _project.push({
          id: this.projectList[i].id,
          hour: this.projectList[i].hour,
          projectId: this.projectList[i].projectId,
          manhourDate: new Date(this.getDate).getTime(),
          workContent: this.projectList[i].workContent,
          workName: this.projectList[i].workName,
          manhourType: 0,
          outgoingType: this.projectList[i].isGoOut ? 'HAVE' : 'NO',
          manhourOutgoing: this.projectList[i].manhourOutgoing,
          deleteOutgoingIdList: this.projectList[i].deleteOutgoingIdList,
          isTraveling: this.projectList[i].isOutWork ? 'Y' : 'N',
          manhourTraveling: this.projectList[i].manhourTraveling,
          deleteTravelingIdList: this.projectList[i].deleteTravelingIdList,
          checkStatus: this.projectList[i].checkStatus == 4 ? this.projectList[i].checkStatus : 1, // 保存状态
          userId: JSON.parse(localStorage.getItem('userInfo')).id, // 登录用户 id
          isTaskProject: this.projectList[i].isTaskProject,
          projectTaskId: this.projectList[i].projectTaskId,
        })
      }
      _project = [..._project, ...this.deleteProjectList]

      for (let i = 0; i < this.businessList.length; i++) {
        _business.push({
          id: this.businessList[i].id,
          hour: this.businessList[i].hour,
          businessId: this.businessList[i].businessId,
          manhourDate: new Date(this.getDate).getTime(),
          workContent: this.businessList[i].workContent,
          workName: this.businessList[i].workName,
          manhourType: 2,
          outgoingType: this.businessList[i].isGoOut ? 'HAVE' : 'NO',
          manhourOutgoing: this.businessList[i].manhourOutgoing,
          deleteOutgoingIdList: this.businessList[i].deleteOutgoingIdList,
          isTraveling: this.businessList[i].isOutWork ? 'Y' : 'N',
          manhourTraveling: this.businessList[i].manhourTraveling,
          deleteTravelingIdList: this.businessList[i].deleteTravelingIdList,
          checkStatus: this.businessList[i].checkStatus == 4 ? this.businessList[i].checkStatus : 1, // 保存状态
          userId: JSON.parse(localStorage.getItem('userInfo')).id, // 登录用户 id
        })
      }
      _business = [..._business, ...this.deleteBusinessList]

      for (let i = 0; i < this.workList.length; i++) {
        _work.push({
          id: this.workList[i].id,
          deptId: this.workList[i].deptId,
          deptIdArr: this.workList[i].deptIdArr,
          departmentId: this.workList[i].deptId,
          hour: this.workList[i].hour,
          projectId: this.workList[i].projectId,
          manhourDate: new Date(this.getDate).getTime(),
          workContent: this.workList[i].workContent,
          workName: this.getUserDeptName(this.workList[i].deptId),
          manhourType: 1,
          outgoingType: this.workList[i].isGoOut ? 'HAVE' : 'NO',
          manhourOutgoing: this.workList[i].manhourOutgoing,
          deleteOutgoingIdList: this.workList[i].deleteOutgoingIdList,
          isTraveling: this.workList[i].isOutWork ? 'Y' : 'N',
          manhourTraveling: this.workList[i].manhourTraveling,
          deleteTravelingIdList: this.workList[i].deleteTravelingIdList,
          checkStatus: this.workList[i].checkStatus == 4 ? this.workList[i].checkStatus : 1, // 保存状态
          userId: JSON.parse(localStorage.getItem('userInfo')).id, // 登录用户 id
          companyType: this.workList[i].companyType,
        })
      }
      let _concat = _project.concat(_work)
      let concat = _concat.concat(_business)
      this.$confirm('填写的项目将重新提交审核，确定修改？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.loadSubmit = true
          if (this.dayForm.isBoss && this.activeName == '2') {
            this.$api.hour
              .saveBatchManhour(concat)
              .then(res => {
                this.loadSubmit = false
                this.$message({
                  message: '提交成功',
                  type: 'success',
                })

                this.handleClose() /** 关闭 “弹出框” **/
                this.projectList = []
                this.workList = []
                this.businessList = []
              })
              .catch(err => {
                this.loadSubmit = false
                console.log(err)
              })
          } else {
            this.$api.hour
              .resubmitManhour(concat)
              .then(res => {
                this.loadSubmit = false
                this.$message({
                  message: '提交成功',
                  type: 'success',
                })

                this.handleClose() /** 关闭 “弹出框” **/
                this.projectList = []
                this.workList = []
                this.businessList = []
              })
              .catch(err => {
                this.loadSubmit = false
                console.log(err)
              })
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消',
          })
          this.loadSubmit = false
        })
    },

    /** 关闭 “弹出框” **/
    handleClose() {
      this.formData = {}
      this.$emit('childEvent', this.activeName)
    },
    // 滚动条置底
    handleScrollBottom() {
      this.$nextTick(() => {
        this.$refs.leftScrollbar.wrap.scrollTop = this.$refs.leftScrollbar.wrap.scrollHeight
      })
    },
    getlistDept() {
      // this.$api.hour
      //   .organizationManhourType()
      //   .then(res => {
      //     this.listDept = res.data
      //   })
      //   .catch(err => {
      //     console.log(err)
      //   })
      this.$api.sysDept
        .getUserDeptTree(this.userInfo.staffId)
        .then(res => {
          this.deptList = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    async getlistProject() {
      this.$api.hour
        .listProjectToManhour()
        .then(async res => {
          this.listProject = []
          //获取排序数据
          const idRes = await this.$api.projectMemberSort.selectDetailStaffId()
          const proIdList = idRes.data?.projectSortList || []
          res.data = this.OrganizeProList(res.data, proIdList)
          for (let c = 0; c < res.data.length; c++) {
            this.listProject.push({
              // 参与的所有项目
              ...res.data[c],
              projectName: res.data[c].projectName,
              projectId: res.data[c].id,
            })

            this.projectList.forEach(item => {
              if (item.projectId == res.data[c].id) {
                find.isTask = res.data[c].isTaskProject == 2 ? true : false
                find.isTaskProject = res.data[c].isTaskProject
                item.xmTaskAssignList = res.data[c].xmTaskAssignList
              }
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
      const business = await this.$api.businessExpense.getBusinessNameList()
      this.listBusiness = business.data || []
    },
    //整理返回回来的项目列表与排序列表
    OrganizeProList(prolist, proIdList) {
      let arr = []
      if (prolist && prolist.length > 0) {
        prolist.forEach(item => {
          let index = proIdList.findIndex(id => id == item.id)
          if (index !== -1) {
            //说明当前项目id在排序列表中存在，直接将item内容替换到排序列表在
            proIdList.splice(index, 1, item)
          } else {
            //如果没有找到，说明该项目是新项目，插入到排序列表最前
            proIdList.unshift(item)
          }
        })
        //再次渲染排序列表查看当前是否存在id
        proIdList = proIdList.filter(item => typeof item == 'object')
        arr = [...proIdList]
      }
      return arr
    },
    onChangeWork() {
      this.selectIdsArr = []
      for (const item of this.workList) {
        if (item.checkStatus == 4) {
          break
        } else if (item.companyType) {
          this.selectIdsArr.push(item.companyType)
        }
      }
    },
    getUserDeptName(id) {
      const dept = this.deptNameList.find(v => v.id === id)
      if (dept) {
        return dept.deptName
      } else {
        return ''
      }
    },
    onChangeProject() {
      this.selectProIdArr = []
      for (const item of this.projectList) {
        if (item.projectId) {
          // selectProIdArr，代表重复的项目
          const findResult = this.selectProIdArr.findIndex(proId => proId == item.projectId)
          if (findResult == -1) {
            // 说明当前项目id在selectProIdArr不存在，记录下来
            this.selectProIdArr.push(item.projectId)
          }
        }
      }
    },
    selectChangeParent(event, index) {
      //event标识当前选中的项目id
      const findResult = this.listProject.find(item => item.projectId == event)
      if (findResult) {
        this.projectList[index].workName = findResult.projectName
        this.projectList[index].projectId = findResult.projectId
        this.projectList[index].isTask = findResult.isTaskProject == 2 ? true : false
        this.projectList[index].isTaskProject = findResult.isTaskProject
        this.projectList[index].xmTaskAssignList = findResult.xmTaskAssignList
      }
      this.onChangeProject()
    },
    onChangeBusiness() {
      this.selectBusinessIdArr = []
      for (const item of this.businessList) {
        if (item.businessId) {
          // selectBusinessIdArr，代表重复的商务
          const findResult = this.selectBusinessIdArr.findIndex(proId => proId == item.businessId)
          if (findResult == -1) {
            // 说明当前商务id在selectBusinessIdArr不存在，记录下来
            this.selectBusinessIdArr.push(item.businessId)
          }
        }
      }
    },
    selectChangeBusiness(event, index) {
      //event标识当前选中的商务id
      const findResult = this.listBusiness.find(item => item.id == event)
      if (findResult) {
        this.businessList[index].workName = findResult.businessName
        this.businessList[index].businessId = findResult.id
        this.businessList[index].businessName = findResult.businessName
      }
      this.onChangeBusiness()
    },

    // 当外出开关change事件触发打开对话框
    onOutSwitchChange(item, index, e, type) {
      this.canGoOutSubmit = true
      let defaultDate = new Date(this.getDate)
      this.outReportData = {
        type: 'WRITTEN',
        startDate: defaultDate,
        endDate: defaultDate,
        place: '',
        content: '',
        manhourFileList: [],
        deleteManhourFileIdList: [],
        fileErrMsg: '',
      }
      this.outReportData.addType = type
      this.outReportData.manhourIndex = index
      // 勾选外出复选框
      if (e) {
        this.isDetailInDialog = false
        this.isOutReportDialogShow = true
      } else {
        // 取消勾选复选框，如果当前行有外出信息，则弹出提示框
        if (item.manhourOutgoing && item.outgoingType == 'HAVE') {
          this.$confirm('取消勾选将删除该重要活动报告, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(() => {
              if (item.manhourOutgoing.id) {
                // 如果该外出报告存在id，则需要存储被删除的id
                item.deleteOutgoingIdList = []
                item.deleteOutgoingIdList.push(item.manhourOutgoing.id)
              }
              item.manhourOutgoing = null
            })
            .catch(() => {
              if (type == 'project') {
                this.projectList[this.outReportData.manhourIndex].isGoOut = true
              } else if (type == 'organization') {
                this.workList[this.outReportData.manhourIndex].isGoOut = true
              } else if (type == 'business') {
                this.businessList[this.outReportData.manhourIndex].isGoOut = true
              }
            })
        }
      }
    },

    // 当关闭对话框前，关闭当前弹框，并重置开关
    onOuReportDialogClose() {
      // 如果是点击查看详情出现的弹框，点击关闭时无需重置开关状态
      if (!this.isDetailInDialog) {
        if (this.outReportData.addType == 'project') {
          this.projectList[this.outReportData.manhourIndex].isGoOut = false
        } else if (this.outReportData.addType == 'organization') {
          this.workList[this.outReportData.manhourIndex].isGoOut = false
        } else if (this.outReportData.addType == 'business') {
          this.businessList[this.outReportData.manhourIndex].isGoOut = false
        }
      }
      this.outReportData = {}
      this.isOutReportDialogShow = false
    },

    upload(r) {
      this.outReportData.manhourFileList.push(...r)
      this.outReportData.fileErrMsg = ''
    },

    del(index) {
      if (this.outReportData.manhourFileList[index].id) {
        this.outReportData.deleteManhourFileIdList.push(
          this.outReportData.manhourFileList[index].id
        )
      }
      this.outReportData.manhourFileList = this.outReportData.manhourFileList.filter(
        (item, i) => i != index
      )
    },
    saveOutReport() {
      // 触发校验
      this.$refs.outReportForm.validate(valid => {
        if (valid) {
          // && this.outReportData.manhourFileList.length > 0
          if (this.outReportData.manhourFileList) {
            if (this.outReportData.addType == 'project') {
              this.projectList[this.outReportData.manhourIndex].manhourOutgoing = {
                ...this.outReportData,
              }
            } else if (this.outReportData.addType == 'organization') {
              this.workList[this.outReportData.manhourIndex].manhourOutgoing = {
                ...this.outReportData,
              }
            } else if (this.outReportData.addType == 'business') {
              this.businessList[this.outReportData.manhourIndex].manhourOutgoing = {
                ...this.outReportData,
              }
            }
            this.isOutReportDialogShow = false
            this.outReportData = {}
          }
          // else {
          //   this.outReportData.fileErrMsg = '请上传附件'
          // }
        }
        // else {
        //   if (this.outReportData.manhourFileList && this.outReportData.manhourFileList.length > 0) {
        //     return false
        //   } else {
        //     this.outReportData.fileErrMsg = '请上传附件'
        //   }
        //   return false
        // }
      })
    },

    openOutReportDialog(item, index, type) {
      if (item.manhourOutgoing.id) {
        // 说明该外出报告已被存储，记录当前打开详情对应的工时id
        this.canGoOutSubmit = true
        if (item.checkStatus == 2 || item.checkStatus == 3) {
          this.canGoOutSubmit = false
        }
        this.isDetailInDialog = true //是详情弹框，关闭时无需重置按钮
        this.outReportData = { ...item.manhourOutgoing }
        this.outReportData.editStatus = 'edit'
        this.outReportData.addType = type
        this.outReportData.manhourIndex = index
        this.outReportData.deleteManhourFileIdList
          ? this.outReportData.deleteManhourFileIdList
          : (this.outReportData.deleteManhourFileIdList = [])
        this.isOutReportDialogShow = true
      } else {
        // 如果没有id说明是新增的，通过下标传入对象
        this.isDetailInDialog = true //是详情弹框，关闭时无需重置按钮
        this.outReportData = { ...item.manhourOutgoing }
        this.outReportData.editStatus = 'add'
        this.outReportData.addType = type
        this.outReportData.manhourIndex = index
        this.isOutReportDialogShow = true
      }
    },

    deleteOutReport() {
      if (this.outReportData.addType == 'project') {
        if (this.outReportData.id) {
          // 说明是数据库中已存储的外出报告，需要删除id
          this.projectList[this.outReportData.manhourIndex].deleteOutgoingIdList = []
          this.projectList[this.outReportData.manhourIndex].deleteOutgoingIdList.push(
            this.outReportData.id
          )
        }
        this.projectList[this.outReportData.manhourIndex].manhourOutgoing = null
        this.projectList[this.outReportData.manhourIndex].isGoOut = false
      } else if (this.outReportData.addType == 'organization') {
        if (this.outReportData.id) {
          // 说明是数据库中已存储的外出报告，需要删除id
          this.workList[this.outReportData.manhourIndex].deleteOutgoingIdList = []
          this.workList[this.outReportData.manhourIndex].deleteOutgoingIdList.push(
            this.outReportData.id
          )
        }
        this.workList[this.outReportData.manhourIndex].manhourOutgoing = null
        this.workList[this.outReportData.manhourIndex].isGoOut = false
      } else if (this.outReportData.addType == 'business') {
        if (this.outReportData.id) {
          // 说明是数据库中已存储的外出报告，需要删除id
          this.businessList[this.outReportData.manhourIndex].deleteOutgoingIdList = []
          this.businessList[this.outReportData.manhourIndex].deleteOutgoingIdList.push(
            this.outReportData.id
          )
        }
        this.businessList[this.outReportData.manhourIndex].manhourOutgoing = null
        this.businessList[this.outReportData.manhourIndex].isGoOut = false
      }
      this.isOutReportDialogShow = false
      this.outReportData = {}
    },

    // 当外出开关change事件触发打开对话框
    onOutWorkSwitchChange(item, index, e, type) {
      this.canOutWorkSubmit = true
      this.outWorkInfo = {
        witness: '',
        content: '',
        manhourFileList: [],
        deleteManhourFileIdList: [],
      }
      this.outWorkInfo.addType = type
      this.outWorkInfo.manhourId = item.id ? item.id : null
      this.outWorkInfo.manhourIndex = index
      // 勾选外出复选框
      if (e) {
        this.isOutWorkDialog = false
        this.isOutWorkDialogShow = true
      } else {
        // 取消勾选复选框，如果当前行有外出信息，则弹出提示框
        if (item.manhourTraveling) {
          this.$confirm('取消勾选将删除该外勤报告, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(() => {
              if (item.manhourTraveling.id) {
                // 如果该外出报告存在id，则需要存储被删除的id
                item.deleteTravelingIdList = []
                item.deleteTravelingIdList.push(item.manhourTraveling.id)
              }
              item.manhourTraveling = null
            })
            .catch(() => {
              if (type == 'project') {
                this.projectList[this.outWorkInfo.manhourIndex].isOutWork = true
              } else if (type == 'organization') {
                this.workList[this.outWorkInfo.manhourIndex].isOutWork = true
              } else if (type == 'business') {
                this.businessList[this.outWorkInfo.manhourIndex].isOutWork = true
              }
            })
        }
      }
    },

    // 当关闭对话框前，关闭当前弹框，并重置开关
    onOutWorkDialogClose() {
      // 如果是点击查看详情出现的弹框，点击关闭时无需重置开关状态
      if (!this.isOutWorkDialog) {
        if (this.outWorkInfo.addType == 'project') {
          this.projectList[this.outWorkInfo.manhourIndex].isOutWork = false
        } else if (this.outWorkInfo.addType == 'organization') {
          this.workList[this.outWorkInfo.manhourIndex].isOutWork = false
        } else if (this.outWorkInfo.addType == 'business') {
          this.business[this.outWorkInfo.manhourIndex].isOutWork = false
        }
      }
      this.outWorkInfo = {}
      this.isOutWorkDialogShow = false
    },

    outWorkUpload(r) {
      this.outWorkInfo.manhourFileList.push(...r)
    },

    outWorkDel(index) {
      if (this.outWorkInfo.manhourFileList[index].id) {
        this.outWorkInfo.deleteManhourFileIdList.push(this.outWorkInfo.manhourFileList[index].id)
      }
      this.outWorkInfo.manhourFileList = this.outWorkInfo.manhourFileList.filter(
        (item, i) => i != index
      )
    },

    saveOutWork() {
      // 触发校验
      if (!this.outWorkInfo.witness) {
        return this.$message.warning('请选择证明人')
      }
      if (this.outWorkInfo.manhourFileList && this.outWorkInfo.manhourFileList.length <= 0) {
        return this.$message.warning('请上传附件')
      }

      if (this.outWorkInfo.addType == 'project') {
        this.projectList[this.outWorkInfo.manhourIndex].manhourTraveling = {
          ...this.outWorkInfo,
        }
      } else if (this.outWorkInfo.addType == 'organization') {
        this.workList[this.outWorkInfo.manhourIndex].manhourTraveling = {
          ...this.outWorkInfo,
        }
      } else if (this.outWorkInfo.addType == 'business') {
        this.businessList[this.outWorkInfo.manhourIndex].manhourTraveling = {
          ...this.outWorkInfo,
        }
      }
      this.isOutWorkDialogShow = false
      this.outWorkInfo = {}
    },

    openOutWorkDialog(item, index, type) {
      if (item.manhourTraveling.id) {
        // 说明该外出报告已被存储，记录当前打开详情对应的工时id
        this.canOutWorkSubmit = true
        if (item.checkStatus == 2 || item.checkStatus == 3) {
          this.canOutWorkSubmit = false
        }
        this.isOutWorkDialog = true //是详情弹框，关闭时无需重置按钮
        this.outWorkInfo = { ...item.manhourTraveling }
        this.outWorkInfo.addType = type
        this.outWorkInfo.manhourIndex = index
        this.outWorkInfo.manhourId = item.id ? item.id : null
        this.outWorkInfo.deleteManhourFileIdList
          ? this.outWorkInfo.deleteManhourFileIdList
          : (this.outWorkInfo.deleteManhourFileIdList = [])
        this.isOutWorkDialogShow = true
      } else {
        // 如果没有id说明是新增的，通过下标传入对象
        this.isOutWorkDialog = true //是详情弹框，关闭时无需重置按钮
        this.outWorkInfo = { ...item.manhourTraveling }
        this.outWorkInfo.addType = type
        this.outWorkInfo.manhourId = item.id ? item.id : null
        this.outWorkInfo.manhourIndex = index
        this.isOutWorkDialogShow = true
      }
    },

    deleteOutWork() {
      if (this.outWorkInfo.addType == 'project') {
        if (this.outWorkInfo.id) {
          // 说明是数据库中已存储的外出报告，需要删除id
          this.projectList[this.outWorkInfo.manhourIndex].deleteTravelingIdList = []
          this.projectList[this.outWorkInfo.manhourIndex].deleteTravelingIdList.push(
            this.outWorkInfo.id
          )
        }
        this.projectList[this.outWorkInfo.manhourIndex].manhourTraveling = null
        this.projectList[this.outWorkInfo.manhourIndex].isOutWork = false
      } else if (this.outWorkInfo.addType == 'organization') {
        if (this.outWorkInfo.id) {
          // 说明是数据库中已存储的外出报告，需要删除id
          this.workList[this.outWorkInfo.manhourIndex].deleteTravelingIdList = []
          this.workList[this.outWorkInfo.manhourIndex].deleteTravelingIdList.push(
            this.outWorkInfo.id
          )
        }
        this.workList[this.outWorkInfo.manhourIndex].manhourTraveling = null
        this.workList[this.outWorkInfo.manhourIndex].isOutWork = false
      } else if (this.outWorkInfo.addType == 'business') {
        if (this.outWorkInfo.id) {
          // 说明是数据库中已存储的外出报告，需要删除id
          this.businessList[this.outWorkInfo.manhourIndex].deleteTravelingIdList = []
          this.businessList[this.outWorkInfo.manhourIndex].deleteTravelingIdList.push(
            this.outWorkInfo.id
          )
        }
        this.businessList[this.outWorkInfo.manhourIndex].manhourTraveling = null
        this.businessList[this.outWorkInfo.manhourIndex].isOutWork = false
      }
      this.isOutWorkDialogShow = false
      this.outWorkInfo = {}
    },

    async downloadFile() {
      window.location.href =
        'https://pro.huitukj.com/iip-api/profile/OPERATION_MANUAL/template/外出活动报告模板.docx'
    },

    projectQuerySearch(queryString, cb) {
      let projectList = this.projectList.map(item => {
        return { value: item.projectName }
      })
      let results = queryString ? this.projectCreateFilter(queryString) : projectList
      // 调用 callback 返回建议列表的数据
      cb(results)
      window.dispatchEvent(new Event('resize'))
    },

    projectCreateFilter(queryString) {
      let results = this.projectList.map(item => {
        return { value: item.projectName }
      })
      // 将输入的内容进行拆分，返回值为一个数组
      const queryStringList = queryString.split('')
      if (queryStringList.length > 0) {
        queryStringList.forEach(queryItem => {
          let filterResults = results.filter(nameItem => nameItem.value?.includes(queryItem))
          results = [...filterResults]
        })
      }
      return results
    },

    onProkectNameSelect(e) {
      if (e) {
        this.searchProjectName = e.value
        let findIndex = this.projectList.findIndex(
          item => item.projectName == this.searchProjectName
        )
        if (findIndex !== -1) {
          let deleteArr = this.projectList.splice(findIndex, 1)
          this.projectList.unshift(deleteArr[0])
        }
        // this.searchProjectName = ''
      }
      this.$forceUpdate()
    },
    businessQuerySearch(queryString, cb) {
      let businessList = this.businessList.map(item => {
        return { value: item.businessName }
      })
      let results = queryString ? this.businessCreateFilter(queryString) : businessList
      // 调用 callback 返回建议列表的数据
      cb(results)
      window.dispatchEvent(new Event('resize'))
    },

    businessCreateFilter(queryString) {
      let results = this.businessList.map(item => {
        return { value: item.businessName }
      })
      // 将输入的内容进行拆分，返回值为一个数组
      const queryStringList = queryString.split('')
      if (queryStringList.length > 0) {
        queryStringList.forEach(queryItem => {
          let filterResults = results.filter(nameItem => nameItem.value?.includes(queryItem))
          results = [...filterResults]
        })
      }
      return results
    },

    onBusinessNameSelect(e) {
      if (e) {
        this.searchBusinessName = e.value
        let findIndex = this.businessList.findIndex(
          item => item.businessName == this.searchBusinessName
        )
        if (findIndex !== -1) {
          let deleteArr = this.businessList.splice(findIndex, 1)
          this.businessList.unshift(deleteArr[0])
        }
      }
      this.$forceUpdate()
    },
  },
}
</script>

<style lang="scss" scoped>
.dialog-title {
  text-align: left;
  overflow: hidden;

  .title-text {
    font-size: 20px;
    letter-spacing: 1px;
    color: #4d4d4d;
  }
}

/deep/ .el-dialog__headerbtn .el-dialog__close {
  color: #333333;
}

.date_div {
  position: absolute;
  left: 50%;
  top: -10px;
  font-size: 26px;
  font-weight: bold;
  color: #333333;
  transform: translate(-50%);
}

.form_box {
  .el-tabs {
    font-size: 18px;
  }
  position: relative;
  overflow: hidden;
  width: 100%;

  .tips_div {
    margin: 15px 0;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .tips_div_left {
      display: flex;
      align-items: center;
      flex: 1;
    }
  }

  .tips_div_height {
    width: 310px;
    height: 25px;
    line-height: 25px;
    color: red;
  }
  .add_btn {
    margin-top: 16px;

    p {
      position: relative;
      width: 150px;
      height: 27px;
      cursor: pointer;

      .add_ico {
        font-size: 24px;
        color: #014d67;
      }

      span {
        position: absolute;
        top: 2px;
        font-size: 15px;
        color: #999999;
        margin-left: 5px;
      }
    }
  }

  .scrollbar_div {
    height: 420px;

    /deep/ .el-scrollbar__wrap {
      overflow-x: hidden;
    }
  }

  .form_header,
  .form_body {
    display: flex;
    li {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .form_header {
    height: 50px;
    line-height: 50px;
    text-align: center;
    background-color: #edf0f5;
  }

  .form_body {
    text-align: center;
  }

  .project_div {
    .form_header {
      li {
        border-right: 1px solid #ebeef5;
      }
      li:first-child {
        width: 25%;
        border-left: 1px solid #ebeef5;
      }
      li:nth-child(2) {
        width: 18%;
        border-left: 1px solid #ebeef5;
      }

      li:nth-child(3),
      li:nth-child(4) {
        width: 9%;
      }
      li:nth-child(5),
      li:nth-child(6) {
        width: 7%;
      }
      li:last-child {
        width: 45%;
      }
    }

    .form_body {
      li {
        position: relative;
        width: 25%;
        padding: 10px 5px;
        border-right: 1px solid #ebeef5;
        border-bottom: 1px solid #ebeef5;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      li:first-child {
        width: 25%;
        border-left: 1px solid #ebeef5;
      }

      li:nth-child(2) {
        width: 20%;
      }

      li:nth-child(3),
      li:nth-child(4),
      li:nth-child(5) {
        width: 8%;
      }
      li:nth-child(6) {
        width: 8%;
      }

      li:nth-child(7) {
        width: 40%;
      }
      li:last-child {
        display: flex;
        flex-direction: column;
        width: 5%;
      }
      .btn-add {
        font-size: 30px;
        margin-bottom: 8px;
        color: #409eff;
      }
      .btn-delete {
        font-size: 30px;
        color: red;
      }
    }
  }
  .business_div {
    .form_header {
      li {
        border-right: 1px solid #ebeef5;
      }

      li:first-child {
        width: 35%;
        border-left: 1px solid #ebeef5;
      }

      li:nth-child(2),
      li:nth-child(3),
      li:nth-child(4),
      li:nth-child(5) {
        width: 11%;
      }

      li:last-child {
        width: 65%;
      }
    }

    .form_body {
      li {
        position: relative;
        width: 25%;
        padding: 10px 5px;
        border-right: 1px solid #ebeef5;
        border-bottom: 1px solid #ebeef5;
        box-sizing: border-box;

        display: flex;
        justify-content: center;
        align-items: center;
      }

      li:first-child {
        width: 35%;
        border-left: 1px solid #ebeef5;
      }

      li:nth-child(2),
      li:nth-child(3),
      li:nth-child(4),
      li:nth-child(5) {
        width: 10%;
      }

      li:nth-child(6) {
        width: 60%;
      }

      li:last-child {
        width: 5%;
      }
    }
  }
  .work_div_margintop {
    margin-top: 20px;
  }
  .work_div {
    .form_header {
      li {
        border-right: 1px solid #ebeef5;
      }

      li:first-child {
        width: 26%;
        border-left: 1px solid #ebeef5;
      }

      li:nth-child(2),
      li:nth-child(3),
      li:nth-child(4) {
        width: 12%;
      }

      li:last-child {
        width: 60%;
      }
    }

    .form_body {
      li {
        position: relative;
        width: 25%;
        padding: 10px 5px;
        border-right: 1px solid #ebeef5;
        border-bottom: 1px solid #ebeef5;
        box-sizing: border-box;
      }

      li:first-child {
        width: 24%;
        border-left: 1px solid #ebeef5;
      }

      li:nth-child(2),
      li:nth-child(3),
      li:nth-child(4) {
        width: 10%;
      }

      li:nth-child(5) {
        width: 50%;
        border-right: 0;
      }

      li:last-child {
        width: 5%;
        padding: 10px 5px 0 0;
        border-bottom: 0;
        border-right: 0;

        box-sizing: border-box;
      }
    }
  }

  /deep/.el-input {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 93%;
    transform: translate(-50%, -50%);
  }
  /deep/.el-select {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 93%;
    transform: translate(-50%, -50%);
  }
}

/deep/.el-dialog__body {
  padding: 0 20px 15px 20px;
}

/deep/ .el-dialog__footer {
  text-align: center;
  /*background-color: #ebeef5;*/
  padding-bottom: 10px;

  .dialog-footer {
    text-align: center;
  }
}

.teal_bg {
  background-color: #014d67;
  border: 1px solid #014d67;
}

.white_bg {
  color: #014d67;
  border: 1px solid #014d67;
}

.lightGrey_bg {
  color: #666666;
  background-color: #eeeeee;
  border: 1px solid #eeeeee;
}

.m_lr {
  margin: 0 15px;
}

.upload-btn-box {
  display: flex;
  align-items: center;
}
.icon-out {
  cursor: pointer;
  font-size: 28px;
  padding-left: 10px;
  vertical-align: middle;
}
.file-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .file-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.out-box {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .out-a {
    margin-top: 15px;
  }
}
.el-icon-question {
  font-size: 20px;
  color: #e6a23c;
}

.fix-dialog-position ::v-deep .el-popper {
  position: absolute !important;
}
</style>
